import React from 'react'
import "./Research.css"
import researchFirstImage from "../Images/researchFirst.webp"
import researchSecondImage from "../Images/researchSecond.webp"
import researchThirdImage from "../Images/researchThird.webp"
import researchFourthImage from "../Images/researchFourth.webp"
import researchFifthImage from "../Images/researchFifth.webp"
import researchSixthImage from "../Images/researchSixth.webp"
import researchSeventhImage from "../Images/researchSeventh.webp"
import Footer from '../MainPage/Footer/Footer'
import { useNavigate } from "react-router";

export default function Research() {
  const navigate = useNavigate()
  const brandConnectClickHandler = () =>{
    navigate("/pages/brand-connect")
}
  return (
    <div>
      <div className='researchSection'>
        <div className='researchFirstSection'>
          <div className='researchFirstSectionData'>
            <div className='researchFirstSectionHeading'>
              PRODUCT RESEARCH AT SMYTTEN
            </div>
            <div className='researchFirstSectionDescription'>
              Explore our customer research solutions. Keep up with the fast pace changes in the market and customers, with our flexible solutions that incorporate guided methodologies and AI-Powered insights to uncover meaningful trends fast - within 72 hours!
            </div>
          </div>
          <div className='researchFirstSectionImage'>
            <img src={researchFirstImage} alt="firstImage" />
          </div>
        </div>
        <div className='researchSecondSection'>
          <div className='researchSecondSectionHeader'>
            <div className='researchSecondSectionHeading'>
              Product Concept Analysis
            </div>
            <div className='researchSecondSectionHeadingData'>
              Test product ideas and compare against industry benchmarks.
            </div>
          </div>
          <div className='researchSecondSectionMiddle'>
            <div className='researchSecondSectionMiddleImage'>
              <img src={researchSecondImage} alt="SecondImage" />
            </div>
            <div className='researchSecondSectionMiddleData'>
              Concept testing helps you take your product to the market with confidence. Get your product concepts, feature optimizations, and ideas validated by a trusted audience within 72 hours.
              <br></br>
              <br></br>
              Product Concept Analysis automatically compares and scores multiple concepts, and provides qualitative feedback to help you identify which will drive the most impact—and why.
            </div>
          </div>
        </div>
        <div className='researchThirdSection'>
          <div className='researchThirdSectionHeader'>
            <div className='researchThirdSectionHeading'>
              Brand Tracker
            </div>
            <div className='researchThirdSectionHeadingData'>
              Measure your brand’s performance across markets, over time.
            </div>
          </div>
          <div className='researchThirdSectionMiddle'>
            <div className='researchThirdSectionMiddleData'>
              Fuel your brand strategy with timely insights. Use our Brand Tracker to spot trends, monitor key metrics, and get up-to-the minute insights on how your brand evolves over time.
              <br></br>
              <br></br>
              Stay on top of your brand health by instantly surfacing meaningful trends and continuously collecting insights from your target consumers.
            </div>
            <div className='researchThirdSectionMiddleImage'>
              <img src={researchThirdImage} alt="ThirdImage" />
            </div>
          </div>
        </div>
        <div className='researchFourthSection'>
          <div className='researchFourthSectionHeader'>
            <div className='researchFourthSectionHeading'>
              Ad Creative Analysis
            </div>
            <div className='researchFourthSectionHeadingData'>
              Test your marketing and advertising campaigns before you launch.
            </div>
          </div>
          <div className='researchFourthSectionMiddle'>
            <div className='researchFourthSectionMiddleImage'>
              <img src={researchFourthImage} alt="FourthImage" />
            </div>
            <div className='researchFourthSectionMiddleData'>
              Measure the effectiveness of your ad creative with an automated analysis of digital ads, signage, and more.
              <br></br>
              <br></br>
              Ad Creative Analysis automatically compares and scores multiple ads, and provides qualitative feedback to help you identify which ad creative will most effectively reach your target market—and why.
            </div>
          </div>
        </div>
        <div className='researchFifthSection'>
          <div className='researchFifthSectionHeader'>
            <div className='researchFifthSectionHeading'>
              Messaging & Claims Analysis
            </div>
            <div className='researchFifthSectionHeadingData'>
              Get feedback on messages, taglines, product copy, and more.
            </div>
          </div>
          <div className='researchFifthSectionMiddle'>
            <div className='researchFifthSectionMiddleData'>
              Measure the effectiveness of your messaging with an automated analysis of slogans, taglines, value propositions, company positioning, and more.
              <br></br>
              <br></br>
              Messaging & Claims Analysis automatically compares and scores multiple messaging concepts, and provides qualitative feedback to help you identify which will drive the most impact—and why.
            </div>
            <div className='researchFifthSectionMiddleImage'>
              <img src={researchFifthImage} alt="FifthImage" />
            </div>
          </div>
        </div>
        <div className='researchSixthSection'>
          <div className='researchSixthSectionHeader'>
            <div className='researchSixthSectionHeading'>
              Usage & Attitude Analysis
            </div>
            <div className='researchSixthSectionHeadingData'>
              Learn about consumer preferences, habits, and purchase behaviors.
            </div>
          </div>
          <div className='researchSixthSectionMiddle'>
            <div className='researchSixthSectionMiddleImage'>
              <img src={researchSixthImage} alt="SixthImage" />
            </div>
            <div className='researchSixthSectionMiddleData'>
              Take your knowledge of your target consumers to the next level. Identify your top consumer segments, what they care about, how they shop, and the best way to get their attention.
              <br></br>
              <br></br>
              Use the Usage & Attitudes solution to build winning product and marketing strategies based on purchase habits and motivations.
            </div>
          </div>
        </div>
        <div className='researchSeventhSection'>
          <div className='researchSeventhSectionHeader'>
            <div className='researchSeventhSectionHeading'>
              Consumer Segmentation Analysis
            </div>
            <div className='researchSeventhSectionHeadingData'>
              Understand the demographic and behavioral traits of your top buyer segments.
            </div>
          </div>
          <div className='researchSeventhSectionMiddle'>
            <div className='researchSeventhSectionMiddleData'>
              Easily profile your ideal consumer segments. Get smart about your target buyers and pinpoint your target consumer segments, what they want, and the best way to get their attention to improve your go-to-market strategy.
              <br></br>
              <br></br>
              Use the Consumer Segmentation solution to launch successful products and campaigns, and uncover the preferences that drive demand.
            </div>
            <div className='researchSeventhSectionMiddleImage'>
              <img src={researchSeventhImage} alt="FifthImage" />
            </div>
          </div>
        </div>
        <div className='mainPageEighthSection'>
          <div className='interestedHeading'>
            Interested in learning more?
          </div>
          <div className="interestedData">
            Get in touch to know how Smytten can help your brand.
          </div>
          <button onClick={brandConnectClickHandler}>
            CONNECT WITH US
          </button>
        </div>
        <Footer/>
      </div>
    </div>
  )
}
