import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import "./Awards.css"
import sliderImage from "../../Images/homepage-banner-new.jpg"
import sliderImage1 from "../../Images/homepage-banner2-new.jpg"
import Carousel from 'react-bootstrap/Carousel'
import juryAwardClaimerImage from "../../Images/juryAwards.png"
import jury2 from "../../Images/jury2.jpg"
import jury4 from "../../Images/jury4.jpg"
import jury5 from "../../Images/jury5.png"
import jury3 from "../../Images/ThirdJury.png"
import jury6 from "../../Images/jury6.jpg"
import jury7 from "../../Images/jury7.jpg"
import jury8 from "../../Images/jury8.jpg"
import jury9 from "../../Images/jury9.jpg"
import 'animate.css';
import JuryDetails from './JuryDetails'
import awardCategory1 from "../../Images/1.png"
import awardCategory2 from "../../Images/2.png"
import awardCategory3 from "../../Images/3.png"
import awardCategory4 from "../../Images/4.png"
import awardCategory5 from "../../Images/5.png"
import awardCategory6 from "../../Images/6.png"


export default function Awards() {
    const [triedLoveState, setTriedLoveState] = useState(false)
    const [industryState, setIndustryState] = useState(true)
    const [sustainabilityState, setSustainabilityState] = useState(false)
    const [juryState, setJuryState] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [jury1state, setJuryState1] = useState(false)
    const [jury2state, setJuryState2] = useState(false)
    const [jury3state, setJuryState3] = useState(false)
    const [jury4state, setJuryState4] = useState(false)
    const [jury5state, setJuryState5] = useState(false)
    const [jury6state, setJuryState6] = useState(false)
    const [jury7state, setJuryState7] = useState(false)
    const [jury8state, setJuryState8] = useState(false)
    const [jury9state, setJuryState9] = useState(false)


    const [award1, setAward1] = useState(false)
    const [award2, setAward2] = useState(false)
    const [award3, setAward3] = useState(false)
    const [award4, setAward4] = useState(false)
    const [award5, setAward5] = useState(false)
    const [award6, setAward6] = useState(false)
    

    const navigate = useNavigate()

    const nominateSmyttenPageClickHandler = () => {
        // navigate("/pages/d2c-nomination")
    }
    const awardCategoryClickHandler = (e) => {
        if (e.target.id !== '') {

            let array = document.querySelectorAll(".awardCategory")
            array.forEach((d, index) => {
                d.classList.remove("selectedCategory")
            })
            document.getElementsByClassName(`awardCategory` + ' ' + e.target.id)[0].classList.add('selectedCategory')
        }
    }

    useEffect(() => {
        if (document.getElementsByClassName(`awardCategory`)[0]) {
            if (document.querySelector(".selectedCategory") === null) {
                document.getElementsByClassName(`awardCategory`)[0].classList.add('selectedCategory')
            } else if (document.querySelectorAll(".selectedCategory").length > 1) {
                document.getElementsByClassName(`awardCategory`)[0].classList.remove('selectedCategory')
            }
        }
    })

    console.log(document.querySelectorAll(".awardCategory one"))
    const triedLoveClickHandler = () => {
        setTriedLoveState(true)
        setIndustryState(false)
        setSustainabilityState(false)
        setJuryState(false)
    }
    const industryClickHandler = () => {
        setTriedLoveState(false)
        setIndustryState(true)
        setSustainabilityState(false)
        setJuryState(false)
    }
    const sustainabilityClickHandler = () => {
        setTriedLoveState(false)
        setIndustryState(false)
        setSustainabilityState(true)
        setJuryState(false)
    }
    const juryClickHandler = () => {
        setTriedLoveState(false)
        setIndustryState(false)
        setSustainabilityState(false)
        setJuryState(true)
    }

    const juryAwardClaimerClickHandler = () => {
        setModalShow(true)
        setJuryState1(true)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }
    const juryAwardClaimerClickHandler3 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(true)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }
    const juryAwardClaimerClickHandler2 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(true)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }
    const juryAwardClaimerClickHandler4 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(true)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }

    const juryAwardClaimerClickHandler5 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(true)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }

    const juryAwardClaimerClickHandler6 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(true)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }

    const juryAwardClaimerClickHandler7 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(true)
        setJuryState8(false)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }


    const juryAwardClaimerClickHandler8 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(true)
        setJuryState9(false)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }


    const juryAwardClaimerClickHandler9 = () => {
        setModalShow(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(true)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
    }




    const forbesNominateClickHandler = () => {
        window.open("https://www.d2cdisruptors.com/", "_blank")
    }

    const awardCatgeoryClickHandler1 = () => {
        setModalShow(true)
        setAward1(true)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }

    const awardCatgeoryClickHandler2 = () => {
        setModalShow(true)
        setAward1(false)
        setAward2(true)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(false)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }

    const awardCatgeoryClickHandler3 = () => {
        setModalShow(true)
        setAward1(false)
        setAward2(false)
        setAward3(true)
        setAward4(false)
        setAward5(false)
        setAward6(false)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }

    const awardCatgeoryClickHandler4 = () => {
        setModalShow(true)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(true)
        setAward5(false)
        setAward6(false)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }

    const awardCatgeoryClickHandler5 = () => {
        setModalShow(true)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(true)
        setAward6(false)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }

    const awardCatgeoryClickHandler6 = () => {
        setModalShow(true)
        setAward1(false)
        setAward2(false)
        setAward3(false)
        setAward4(false)
        setAward5(false)
        setAward6(true)
        setJuryState1(false)
        setJuryState2(false)
        setJuryState3(false)
        setJuryState4(false)
        setJuryState5(false)
        setJuryState6(false)
        setJuryState7(false)
        setJuryState8(false)
        setJuryState9(false)
    }
    return (
        <div>
            <div className='forbesSectionSlider'>
                <Carousel variant="dark">
                    <Carousel.Item className="slideCarousel">
                        <img
                            className="d-block w-100"
                            src={sliderImage}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    {/* <Carousel.Item className="slideCarousel">
                        <img
                            onClick={nominateSmyttenPageClickHandler}
                            className="d-block w-100"
                            src={sliderImage1}
                            alt="Second slide"
                        />
                    </Carousel.Item> */}
                </Carousel>
            </div>
            <div className='nominationAwardsSection'>
                <div className='nominationAwardsFirstSection'>
                    <div className='nominationAwardsFirstSectionHeader'>
                        AWARDS
                    </div>
                    <p>
                        D2C brands are on the rise, and have witnessed a massive uptick across the country in the last few years aided by the internet and smartphone penetration. Several brands have hit the fast lane since the pandemic and today, India, world's fifth-largest retail destination, has over 800 successful D2C brands with a sector valuation of $44.6 Bn (2021 KPMG report). The sector accounts for more than ten percent of the country's GDP and approximately eight percent of employment. By 2025, the D2C market in India is expected to be worth more than $100 billion.
                        <br></br>
                        <br>
                        </br>
                        Smytten has partnered with Forbes India to identify and recognise the trailblazing D2C brands across the categories who stand out from their peers in terms of product quality, customer experience, marketing strategy, growth, sustainability, innovation and future potential. The winners of D2C Disruptor Awards will be announced at a gala ceremony in New Delhi, in November 2022.
                    </p>
                </div>
                <div className='nominationAwardsSecondSection'>
                    <div className='nominationAwardsSecondSectionHeader'>
                        CATEGORIES
                    </div>
                    <div className='nominationAwardsSecondSectionMenu' onClick={awardCategoryClickHandler}>
                        <div className='awardCategory two' id='two' onClick={industryClickHandler}>
                            Industry
                        </div>
                        {
                            industryState ?
                                <>
                                    <div className='awardsSection mobile animate__animated animate__fadeIn'>
                                        <div className='awardsOver'>
                                            <div className='awardsOverHeader'>
                                                Best D2C brands <b>over 5 years</b>
                                            </div>
                                            <p>
                                                Incorporation of organization more than 5 years
                                            </p>
                                            <button onClick={forbesNominateClickHandler}>
                                                NOMINATE NOW
                                            </button>
                                        </div>
                                        <div className='awardsUnder'>
                                            <div className='awardsUnderHeader'>
                                                Best D2C brands <b>under 5 years</b>
                                            </div>
                                            <p>
                                                Incorporation of organization less than 5 years
                                            </p>
                                            <button onClick={forbesNominateClickHandler}>
                                                NOMINATE NOW
                                            </button>
                                        </div>
                                        <div className='awardsImage'>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory1} alt='awardCategories' onClick={awardCatgeoryClickHandler1} />
                                                <div>
                                                    Beauty &<br></br> Personal Care
                                                </div>
                                            </div>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory2} alt='awardCategories' onClick={awardCatgeoryClickHandler2} />
                                                <div>
                                                    Health & <br></br>Wellness
                                                </div>
                                            </div>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory3} alt='awardCategories' onClick={awardCatgeoryClickHandler3} />
                                                <div>
                                                    Food & <br></br>Beverages
                                                </div>
                                            </div>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory4} alt='awardCategories' onClick={awardCatgeoryClickHandler4} />
                                                <div>
                                                    Home &<br></br> Lifestyle
                                                </div>
                                            </div>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory5} alt='awardCategories' onClick={awardCatgeoryClickHandler5} />
                                                <div>
                                                    Mom & Baby
                                                </div>
                                            </div>
                                            <div className='awardsCategories'>
                                                <img src={awardCategory6} alt='awardCategories' onClick={awardCatgeoryClickHandler6} />
                                                <div>
                                                    Sports, Fitness &<br></br> Tech Wearables
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : ""
                        }
                        <div className='awardCategory three' id='three' onClick={sustainabilityClickHandler}>
                            Sustainability & Innovation
                        </div>
                        {
                            sustainabilityState ? <>
                                <div className='awardsSection mobile animate__animated animate__fadeIn'>
                                    <div className='awardsOver'>
                                        <div className='awardsOverHeader'>
                                            Sustainable Brand of the Year
                                        </div>
                                        <p>
                                            To recognize a brand that has taken steps towards sustainable environment which has also impacted the scalability of business operations. The initiative can be manufacturing process upgradation or reusing of non-recyclable products (like plastics), etc.
                                        </p>
                                        <button onClick={forbesNominateClickHandler}>
                                            NOMINATE NOW
                                        </button>
                                    </div>
                                    <div className='awardsUnder'>
                                        <div className='awardsUnderHeader'>
                                            Product Innovation of the Year
                                        </div>
                                        <p>
                                            To recognize the product innovation by any brand that has caused disruption or change in its respective industry/product category. Innovation of the product can be a new product category, or upgradation of packaging, aesthetics, ingredients, etc.
                                        </p>

                                        <button onClick={forbesNominateClickHandler}>
                                            NOMINATE NOW
                                        </button>
                                    </div>
                                    <div className='sustainabilityDisclaimer' >
                                        Open For All
                                    </div>
                                </div>

                            </> : ""
                        }
                        <div className='awardCategory four' id='four' onClick={juryClickHandler}>
                            Jury Awards
                        </div>
                        {
                            juryState ? <>
                                <div className='awardsSection mobile animate__animated animate__fadeIn'>
                                    <div className='awardsOver'>
                                        <div className='awardsOverHeader'>
                                            D2C Disruptor of the Year
                                        </div>
                                        <p>
                                            A brand that has changed the game within its industry/ product category with development of a new product or marketing campaign, etc.
                                        </p>
                                    </div>
                                    <div className='awardsUnder'>
                                        <div className='awardsUnderHeader'>
                                            Marketing Campaign of the Year
                                        </div>
                                        <p>
                                            A marketing campaign that has innovatively connected with its target audiences with clear and innovative communication using various media (TV, radio, print, digital, audio, etc.)
                                        </p>
                                    </div>
                                    <div className='sustainabilityDisclaimer'>
                                        The category is not open for nominations. The winner will be determined by the jury on derivative.
                                    </div>
                                </div>
                            </> : ""
                        }
                        <div className='awardCategory one' id='one' onClick={triedLoveClickHandler}>
                            Tried & Loved - <br></br> Smytten User Choice Award
                        </div>
                        {
                            triedLoveState ? <>
                                <div className='triedLoveNominateSection mobile animate__animated animate__fadeIn'>
                                    <div className='awardsOverHeader'>
                                        Tried & Loved - Smytten User Choice Award
                                    </div>
                                    <p>
                                        To recognize a product by any D2C brand, on the basis of user feedback, rating & reviews received, after successful digital product sampling on the Smytten app.
                                    </p>
                                    <button onClick={nominateSmyttenPageClickHandler} className='nominationButton'>
                                        NOMINATIONS CLOSED
                                    </button>
                                </div>
                            </> : ""
                        }
                    </div>
                    <div className='nominationAwardsSecondSectionMenuData'>

                        {
                            industryState ?
                                <>
                                    <div className='awardsSection animate__animated animate__fadeIn'>
                                        <div className='awardsOver'>
                                            <div className='awardsOverHeader'>
                                                Best D2C brands <b>over 5 years</b>
                                            </div>
                                            <p>
                                                Incorporation of organization more than 5 years
                                            </p>
                                            <button onClick={forbesNominateClickHandler}>
                                                NOMINATE NOW
                                            </button>
                                        </div>
                                        <div className='awardsUnder'>
                                            <div className='awardsUnderHeader'>
                                                Best D2C brands <b>under 5 years</b>
                                            </div>
                                            <p>
                                                Incorporation of organization less than 5 years
                                            </p>
                                            <button onClick={forbesNominateClickHandler}>
                                                NOMINATE NOW
                                            </button>
                                        </div>
                                    </div>
                                    <div className='awardsImage animate__animated animate__fadeIn'>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory1} alt='awardCategories' onClick={awardCatgeoryClickHandler1} />
                                            <div>
                                                Beauty &<br></br> Personal Care
                                            </div>
                                        </div>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory2} alt='awardCategories' onClick={awardCatgeoryClickHandler2} />
                                            <div>
                                                Health & <br></br>Wellness
                                            </div>
                                        </div>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory3} alt='awardCategories' onClick={awardCatgeoryClickHandler3} />
                                            <div>
                                                Food & <br></br>Beverages
                                            </div>
                                        </div>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory4} alt='awardCategories' onClick={awardCatgeoryClickHandler4} />
                                            <div>
                                                Home &<br></br> Lifestyle
                                            </div>
                                        </div>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory5} alt='awardCategories' onClick={awardCatgeoryClickHandler5} />
                                            <div>
                                                Mom & Baby
                                            </div>
                                        </div>
                                        <div className='awardsCategories'>
                                            <img src={awardCategory6} alt='awardCategories' onClick={awardCatgeoryClickHandler6} />
                                            <div>
                                                Sports, Fitness &<br></br> Tech Wearables
                                            </div>
                                        </div>
                                    </div>
                                    <div className='awardsBackGroundColor'>

                                    </div>
                                </>
                                : ""
                        }
                        {
                            sustainabilityState ? <>
                                <div className='awardsSection animate__animated animate__fadeIn'>
                                    <div className='awardsOver'>
                                        <div className='awardsOverHeader'>
                                            Sustainable Brand of the Year
                                        </div>
                                        <p>
                                            To recognize a brand that has taken steps towards a sustainable environment which has also impacted the scalability of business operations. The initiative can be manufacturing process upgradation or reusing of non-recyclable products (like plastics), etc.
                                        </p>
                                        <button onClick={forbesNominateClickHandler}>
                                            NOMINATE NOW
                                        </button>
                                    </div>
                                    <div className='awardsUnder'>
                                        <div className='awardsUnderHeader'>
                                            Product Innovation of the Year
                                        </div>
                                        <p>
                                            To recognize the product innovation by any brand that has caused disruption or change in its respective industry/product category. Innovation of the product can be a new product category, or upgradation of packaging, aesthetics, ingredients, etc.
                                        </p>
                                        <button onClick={forbesNominateClickHandler}>
                                            NOMINATE NOW
                                        </button>
                                    </div>
                                </div>
                                <div className='sustainabilityDisclaimer animate__animated animate__fadeIn' >
                                    Open For All
                                </div>
                            </> : ""
                        }
                        {
                            juryState ? <>
                                <div className='awardsSection animate__animated animate__fadeIn'>
                                    <div className='awardsOver'>
                                        <div className='awardsOverHeader'>
                                            D2C Disruptor of the Year
                                        </div>
                                        <p>
                                            A brand that has changed the game within its industry/ product category with development of a new product or marketing campaign, etc.
                                        </p>
                                    </div>
                                    <div className='awardsUnder'>
                                        <div className='awardsUnderHeader'>
                                            Marketing Campaign of the Year
                                        </div>
                                        <p>
                                            A marketing campaign that has innovatively connected with its target audiences with clear and innovative communication using various media (TV, radio, print, digital, audio, etc.)
                                        </p>
                                    </div>
                                </div>
                                <div className='sustainabilityDisclaimer animate__animated animate__fadeIn'>
                                    The category is not open for nominations. The winner will be determined by the jury on derivative.
                                </div>
                            </> : ""
                        }
                        {
                            triedLoveState ? <>
                                <div className='triedLoveNominateSection animate__animated animate__fadeIn'>
                                    <div className='awardsOverHeader'>
                                        Tried & Loved - Smytten User Choice Award
                                    </div>
                                    <p>
                                        To recognize a product by any D2C brand, on the basis of user feedback, rating & reviews received, after successful digital product sampling on the Smytten app.
                                    </p>
                                    <button onClick={nominateSmyttenPageClickHandler} className='nominationButton'>
                                        NOMINATIONS CLOSED
                                    </button>
                                </div>
                            </> : ""
                        }
                    </div>
                </div>
            </div>
            <div className='juryListSection'>
                <div className='juryListMainSection'>
                    {/* <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler}>
                        <img src={juryAwardClaimerImage} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                KANWALJIT SINGH
                            </div>
                            <p>
                                Founder and Managing Partner at Fireside Ventures
                            </p>
                            <div className='juryListSocialAccounts'>
                                <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} />
                            </div>
                        </div>
                    </div> */}
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler2}>
                        <img src={jury2} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                RAMA BIJAPURKAR
                            </div>
                            <p>
                                Researcher, Academic, Independent Director
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler3}>
                        <img src={jury3} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                RAJAN ANANDAN
                            </div>
                            <p>
                                Managing Director, Sequoia Capital
                            </p>
                            <div className='juryListSocialAccounts'>
                                <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} />
                            </div>
                        </div>
                    </div> */}
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler4}>
                        <img src={jury4} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                SANTOSH DESAI
                            </div>
                            <p>
                                Managing Director & CEO, Future Brands Ltd
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler5}>
                        <img src={jury5} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                SONAL DABRAL
                            </div>
                            <p>
                                Creative Consultant
                            </p>
                            <div className='juryListSocialAccounts'>
                                <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} />
                            </div>
                        </div>
                    </div> */}
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler6}>
                        <img src={jury6} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                Gurmit Singh
                            </div>
                            <p>
                                General Manager, APAC & MEA Quora
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler7}>
                        <img src={jury7} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                Kavitha Krishnarao
                            </div>
                            <p>
                                Managing Director & Lead - Retail, Accenture India
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler8}>
                        <img src={jury8} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                Vinay Singh
                            </div>
                            <p>
                                Partner, Fireside Ventures
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='juryAwardClaimerSection' onClick={juryAwardClaimerClickHandler9}>
                        <img src={jury9} alt='juryList' />
                        <div className='juryAwardClaimerData'>
                            <div className='juryListName'>
                                Rajeev Malik
                            </div>
                            <p>
                                Marketing & Brand Evangelist, Storyteller, Speaker
                            </p>
                            <div className='juryListSocialAccounts'>
                                {/* <FontAwesomeIcon icon={faFacebookF} />
                                <FontAwesomeIcon icon={faTwitter} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faLinkedin} /> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <JuryDetails
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                jury1state={jury1state}
                jury2state={jury2state}
                jury3state={jury3state}
                jury4state={jury4state}
                jury5state={jury5state}
                jury6state={jury6state}
                jury7state={jury7state}
                jury8state={jury8state}
                jury9state={jury9state}
                award1={award1}
                award2={award2}
                award3={award3}
                award4={award4}
                award5={award5}
                award6={award6}
            />
        </div>
    )
}
