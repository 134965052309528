import React from 'react'
import "./SuccessStories.css"
import successFirstImage from "../Images/successFirst.webp"
import successSecondImage from "../Images/successSecond.webp"
import successThirdImage from "../Images/successThird.webp"
import successFourthImage from "../Images/successFourth.webp"
import successFifthImage from "../Images/successFifth.webp"
import Footer from '../MainPage/Footer/Footer'
import { useNavigate } from "react-router";

export default function SuccessStories() {
  const navigate = useNavigate()
  const brandConnectClickHandler = () =>{
    navigate("/pages/brand-connect")
}
  return (
    <div>
      <div className='successStoriesSection'>
        <div className='successStoriesFirstSection'>
          <div className='successStoriesFirstSectionData'>
            <div className='successStoriesFirstSectionHeading'>
              SUCCESS STORIES
            </div>
            <div className='successStoriesFirstSectionDescription'>
              Get inspiration from other brands in your category. Check out how Smytten has been taking companies of all sizes (global giants as well as mid-market and smaller emerging brands) to new heights.
            </div>
          </div>
          <div className='successStoriesFirstSectionImage'>
            <img src={successFirstImage} alt="firstImage" />
          </div>
        </div>
        <div className='successStoriesSecondSection'>
          <div className='successStoriesSecondSectionHeader'>
            <div className='successStoriesSecondSectionHeading'>
              New Product Testing via Trials
            </div>
            <div className='successStoriesSecondSectionHeadingData'>
              Helping brands gauge customer behavior for successful product launches
            </div>
          </div>
          <div className='successStoriesSecondSectionMiddle'>
            <div className='successStoriesSecondSectionMiddleImage'>
              <img src={successSecondImage} alt="SecondImage" />
            </div>
            <div className='successStoriesSecondSectionMiddleData'>
              A 4-year old Indian brand, that had a significant presence in the online market for skin care products, was planning to expand into base makeup.
              <br></br>
              <br></br>
              <ul>
                <li>
                  Launched 3 different ranges across 1.5 years
                </li>
                <li>
                  Pre-launch Smytten product trials done for all 3 ranges across 6 lac users
                </li>
                <li>
                  Over 1.9 lac feedbacks collected from consumers about the trial products
                </li>
                <li>
                  80% positive feedback with a high intent to purchase
                </li>
                <li>
                  Negative feedback on strong fragrance in one of their products and thin fluidity in another helped brand to modify the products to better suit their TG
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='successStoriesThirdSection'>
          <div className='successStoriesThirdSectionHeader'>
            <div className='successStoriesThirdSectionHeading'>
              Driving Consideration via Targeted Trials
            </div>
            <div className='successStoriesThirdSectionHeadingData'>
              Helping brands discover untapped markets with potential customer base and thus expanding the business for them
            </div>
          </div>
          <div className='successStoriesThirdSectionMiddle'>
            <div className='successStoriesThirdSectionMiddleData'>
              Bridging the gap between Metro & Non-Metro cities by creating consideration in low awareness geographies (with no physical presence) for an international skin care brand.
              <br></br>
              <br></br>
              <ul>
                <li>
                  There was a 2x increase in purchase intent & brand awareness post sampling amongst the non-metro users.
                </li>
                <li>
                  Sampling was used as a key marketing lever by the brand to drive business in non-metro cities without any store presence.
                </li>
              </ul>
            </div>
            <div className='successStoriesThirdSectionMiddleImage'>
              <img src={successThirdImage} alt="ThirdImage" />
            </div>
          </div>
        </div>
        <div className='successStoriesFourthSection'>
          <div className='successStoriesFourthSectionHeader'>
            <div className='successStoriesFourthSectionHeading'>
              Re-engagement & Repeat
            </div>
            <div className='successStoriesFourthSectionHeadingData'>
              Using user's past behavioral pattern to target repeat sampling & marketing to drive cross selling and repeat purchase
            </div>
          </div>
          <div className='successStoriesFourthSectionMiddle'>
            <div className='successStoriesFourthSectionMiddleImage'>
              <img src={successFourthImage} alt="FourthImage" />
            </div>
            <div className='successStoriesFourthSectionMiddleData'>
              A niche argan oil based skin care brand with high retention for their top 5 products but very low repeat rate in the rest of their portfolio. Continuous sampling done of different variants to a controlled group of hyper-targeted users communicating different benefits across key geographies.
              <br></br>
              <br></br>
              <ul>
                <li>
                  30% increase in repeat purchase rates
                </li>
                <li>
                  20% users in controlled group went for more than 2 variant purchase during the trial products phase
                </li>
                <li>
                  Sales growth purely led by structured sampling enabled by deep geo & behavioral targeting feature on Smytten
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='successStoriesFifthSection'>
          <div className='successStoriesFifthSectionHeader'>
            <div className='successStoriesFifthSectionHeading'>
              Premium Portfolio Adoption
            </div>
            <div className='successStoriesFifthSectionHeadingData'>
              Adoption driven for super premium products via sampling to build trust, tackle user inhibitors & induce purchase
            </div>
          </div>
          <div className='successStoriesFifthSectionMiddle'>
            <div className='successStoriesFifthSectionMiddleData'>
              A globally renowned French skin care & beauty brand chose Smytten to sample one of its premium range of face serums.
              <br></br>
              <br></br>
              <ul>
                <li>
                  Targeted sampling to Smytten’s most affluent customer base basis income grouping
                </li>
                <li>
                  5X lesser cost of sampling as compared to their existing sampling channels
                </li>
                <li>
                  Direct consumer engagement through the consultation feature on Smytten
                </li>
                <li>
                  Content remarketing based on feedback
                </li>
              </ul>
            </div>
            <div className='successStoriesFifthSectionMiddleImage'>
              <img src={successFifthImage} alt="FifthImage" />
            </div>
          </div>
        </div>
        <div className='mainPageEighthSection'>
          <div className='interestedHeading'>
            Interested in learning more?
          </div>
          <div className="interestedData">
            Get in touch to know how Smytten can help your brand.
          </div>
          <button onClick={brandConnectClickHandler}>
            CONNECT WITH US
          </button>
        </div>
        <Footer/>
      </div>
    </div>
  )
}
