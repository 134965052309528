import React from 'react'
import { useNavigate } from 'react-router'
import "../../NotFound/NotFound.css"

export default function NominationClosed() {
  const navigate = useNavigate()
  const homeClickHandler = () => {
    navigate("/")
  }
  return (
    <div className='notFoundSection'>
      <div className='notFoundSectionHeader'>
      Nominations are now closed!
      </div>
      <button onClick={homeClickHandler} style={{marginTop:'2rem'}}>
        CONTINUE
      </button>
    </div>
  )
}