import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "./JuryDetails.css"

export default function JuryDetails(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='juryModal'
        >
            <Modal.Header closeButton className='juryModalHeader'>
            </Modal.Header>
            <Modal.Body className='juryModalBody'>
                {
                    props.jury1state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Kanwaljit Singh
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Founder and Managing Partner at Fireside Ventures
                            </div>
                            <p>
                                Kanwal has been passionate about mentoring and helping build consumer brands in India, and has been involved as an investor with brands like Paper Boat, ID Fresh, Epigamia, and Licious, before starting Fireside. He has been investing in the early stage ecosystem in India for the past 17 years, first as a Director at Carlyle Group, India, and later as a Co-Founder and Senior Managing Director of Helion Venture Partners. Prior to his investing career, Kanwal spent over 13 years in consumer marketing with Hindustan Unilever, and Intel India.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury2state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Rama Bijapurkar
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Researcher, Academic, Independent Director
                            </div>
                            <p>
                                Rama Bijapurkar is a recognised thought leader on market strategy and consumer behaviour as well as a keen commentator on social and cultural change in India, and a familiar face in boardrooms of India Inc and related social and research organisations.
                                She is also a researcher, academic, independent director on several Indian boards, author of books on Consumer India and on business-market strategy.

                                Ms. Bijapurkar has been a dominant voice in the media on business and policy issues through her writing and is author of hallmark books on Consumer India and is a Professor of Management Practice at Indian Institute of Management, Ahmedabad.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury3state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Rajan Anandan
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Managing Director, Sequoia Capital
                            </div>
                            <p>
                                Rajan Anandan is the Managing Director at Sequoia Capital. He acts as an investment advisor and mentor to the world's top scale-up program 'Surge' for start-ups. Before joining Sequoia, Rajan was leading Google's India business. His career includes leadership roles at Microsoft, Dell and McKinsey & Company. Rajan is BSc. in Mechanical Engineering from Massachusetts Institute of Technology. He further studied Msc. in Manufacturing and System Engineering from Stanford University.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury4state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Santosh Desai
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Managing Director & CEO, Future Brands Ltd
                            </div>
                            <p>
                                Santosh Desai, is an author, columnist and media critic. His first book on contemporary India, Mother Pious Lady: Making Sense of Everyday India, is a collection of essays that traces the evolution of middle-class India over the last two decades. In his professional life, Santosh heads a brand and consumer consultancy company and has previously headed an advertising agency. Santosh's principal area of interest lies in examining the evolving nature of consumer culture in India. He has been writing a weekly column in the Times of India for over 14 years.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury5state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Sonal Dabral
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Creative Consultant
                            </div>
                            <p>
                                Sonal Dabral is a writer/ director and a creative consultant based out of Mumbai, India. Deeply passionate about advertising, blackjack and single malts, Sonal was born in India and studied Visual Communication at India's leading design school - the National Institute of Design.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury6state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Gurmit Singh
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                General Manager, APAC & MEA Quora
                            </div>
                            <p>
                                Gurmit Singh is currently the General Manager of Quora for APAC & MEA geography. He was earlier the India VP & Managing Director of Yahoo. Before joining Quora, he had his own edtech startup called édpurple. Known for his Leadership in the Internet - Mobile - Video space, he has first hand experience, significant achievements and vast functional knowledge in Advertising Sales, Advertising Technology, Consumer Marketing, User Acquisition, Consumer Sales, Subscriptions and Product Management. He has been conferred with an Honorary Doctorate for his outstanding contribution in the Internet & Media space.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury7state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Kavitha Krishnarao
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Managing Director & Lead - Retail, Accenture India

                            </div>
                            <p>
                                Kavitha has 23+ years of professional experience in the retail and consumer industry across both operating and consulting roles and has worked across multiple geographies. In her current role at Accenture as Managing Director, she leads the Retail industry practice. In her last role, prior to Accenture, she was the Chief Commercial Officer for IKEA in India leading brand positioning, growth and profitability of the business. She led core business functions across sales, marketing, sourcing, customer experience, home furnishing and food, for physical stores and ecommerce. As part of the India management team since inception, she has built and led large and diverse teams across supply chain, store operations and commercial to create & implement the long-term strategy of IKEA in India. She has also worked for Capgemini, Walmart, Target, Infosys and Hindustan Unilever across many different roles including supply chain transformation, merchandising and business development. She enjoys working with people and supporting them in their development and is an Associate Certified Coach (ACC) from the International Coaching Federation. Outside of work, she is a mountaineer and enjoys travel, arts and writing.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury8state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Vinay Singh
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Partner, Fireside Ventures
                            </div>
                            <p>
                                Vinay Singh comes with 15 years of experience in FMCG, consumer brands, and technology industries and has built deep expertise in areas of brand management, sales, marketing & digital commerce. At Fireside, Vinay champions the Direct to Consumer (D2C) strategy for the portfolio, leads fundraising and thesis building, and overlooks the internal knowledge and expertise building through playbook creation, benchmarking & ecosystem development. He is on the board of 6 portfolio companies (91Cycles, Azani, 10Club, Gynoveda, Samosa Singh, and Pilgrim) and has played a key role in mentoring multiple portfolio companies on their D2C scale up including Kapiva, Mamaearth, Boat, Slurrp Farm & Vahdam. Prior to joining Fireside as an Advisor - Digital Practice at McKinsey, Vinay advising several multinational public listed companies on their digital transformation and supported in building digital products and tools to implement the change. Prior to that, as the co-founder and CEO of Stepni, Vinay was responsible for the tech, strategy & scale up and orchestrated a successful exit to Quikr with a Tech IP sale. Prior to this, Vinay was the Marketing Head at Bank Bazaar where he was instrumental in scaling consumer traction and led the fundraising. Vinay's career started with Hindustan Unilever (2007 to 2014) where he focused on driving sales, building brands and integrating technology into the core business. Vinay holds an MBA degree from IIM-Bangalore and a B.Tech from Cochin University.
                            </p>
                        </div>
                        : ""
                }
                {
                    props.jury9state === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Rajeev Malik
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                Marketing & Brand Evangelist, Storyteller, Speaker
                            </div>
                            <p>
                                Rajeev is a seasoned marketing and brand professional who has nurtured several prominent brands in India and internationally, some of which are Mahindra, TATA, Eureka Forbes and Blue Star. He has had wide international and multicultural exposure, while managing, TOYOTA, LEXUS & DAIHATSU with a portfolio of 20+ product brands in the Middle East, helping maintain market leadership. Rajeev was honoured with the coveted award, '50 Most Talented CMOs of India', in 2013 and in 2014, the World Marketing Congress conferred the Brand Excellence Award to M&M for innovative marketing. The brand Mahindra has won the coveted Apollo-CV Awards year after year. Over a period of time, he is referred to as the Guarantee Man of Mahindra. He is also a regular Grand Jury member of prestigious marketing awards like EMVIES, EFFIES, ABBYS, etc
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award1 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Beauty & Personal Care
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                To recognize brands manufacturing beauty & personal care products like hair care, skin care, men’s grooming, makeup, bath & body, etc.

                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award2 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Health & Wellness
                            </div>
                            <div className='juryPopUpSectionHeaderData'>

                                To recognize brands manufacturing health & wellness products like protein powders, supplements, health beverages & drinks, etc.
                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award3 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Food & Beverages
                            </div>
                            <div className='juryPopUpSectionHeaderData'>

                                To recognize brands manufacturing food & beverage products like packaged foods, dairy products, confectioneries, snacks, soft drinks, beverages, ready-to-eat, etc.
                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award4 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Home & Lifestyle
                            </div>
                            <div className='juryPopUpSectionHeaderData'>

                                To recognize brands manufacturing home & lifestyle products like candles, furniture, home decor, beddings, etc.
                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award5 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Mom & Baby
                            </div>
                            <div className='juryPopUpSectionHeaderData'>

                                To recognize brands manufacturing mom & baby products like baby lotions, baby shampoo, diapers, body oils for mothers & babies, stretch mark creams/gels, etc.
                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
                {
                    props.award6 === true ?
                        <div className='juryPopUpSection'>
                            <div className='juryPopUpSectionHeader'>
                                Sports, Fitness & Tech Wearables
                            </div>
                            <div className='juryPopUpSectionHeaderData'>
                                To recognize brands manufacturing sports, fitness and tech wearable products like sports shoes, fitness bands, head phones, earphones, gym wear, etc.

                            </div>
                            <p>
                            </p>
                        </div>
                        : ""
                }
            </Modal.Body>
        </Modal>
    )
}
