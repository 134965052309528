import React, { useState,useEffect } from 'react'
import Footer from '../MainPage/Footer/Footer'
import "./BrandConnect.css";
import axios from "axios"
let emailBorder
let nameBorder
let phoneNumberBorder
let companyNameBorder
let sizeOfCompanyBorder
let categoryBorder
let messageBorder
export default function BrandConnect() {
    const initialValues = { username: "", email: "", phoneNumber: "", companyName: "", sizeOfCompany: "", category: "", message: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const submitClickHandler = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0) {
            axios.post(`https://ancillary-prod.smytten.com/ancillary_backend/v1/forbes/contactus/`,
                formValues
            )
                .then(res => {
                    // props.setModalShow(false)
                    setFormValues({ username: "", email: "", phoneNumber: "", companyName: "", sizeOfCompany: "", category: "", message: "" })
                })
        }
    }

    const validate = (values) => {
        const errors = {};
        let color = '#EE504F'
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.username) {
            errors.username = "Username is required!";
            nameBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.email) {
            errors.email = "Email is required!";
            emailBorder = color
            errors.errorMessage = "Please fill required fields"
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            emailBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Phone number not entered'
            phoneNumberBorder = color
            errors.errorMessage = "Please fill required fields"
        } else if (!/^\d+$/.test(values.phoneNumber) || values.phoneNumber.length !== 10) {
            errors.phoneNumber = 'Phone number not entered'
            phoneNumberBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.companyName) {
            errors.companyName = 'company name not entered'
            companyNameBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.sizeOfCompany) {
            errors.sizeOfCompany = 'please enter the company size'
            sizeOfCompanyBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.category) {
            errors.category = 'please enter the category'
            categoryBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.message) {
            errors.message = 'please enter the message'
            messageBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        return errors;
    };

    useEffect(() => {
        if (formValues.email) {
            emailBorder = 'white'
        }
        if (formValues.username) {
            nameBorder = 'white'
        }
        if (formValues.phoneNumber) {
            phoneNumberBorder = 'white'
        } else if (/^\d+$/.test(formValues.phoneNumber) && formValues.length === 10) {
            phoneNumberBorder = 'white'
        }
        if (formValues.companyName) {
            companyNameBorder = 'white'
        }
        if (formValues.sizeOfCompany) {
            sizeOfCompanyBorder = 'white'
        }
        if (formValues.category) {
            categoryBorder = 'white'
        }
        if (formValues.message) {
            messageBorder = 'white'
        }
        if (formValues.email && formValues.username && formValues.phoneNumber && formValues.companyName && formValues.sizeOfCompany && formValues.category && formValues.message) {
            formErrors.errorMessage = ''
        }
    }, [formValues])
    return (
        <div className='brandConnectMainSection'>
            <div className='brandConnectSection'>
                <div className='brandConnectSectionHeader'>
                    Brand Connect | Advertise With Smytten
                </div>
                <div className='brandConnectSectionData'>
                    At Smytten, we’ve always loved hearing from all of you out there!
                    <br></br>
                    <br></br>
                    For any brand-related enquiry, just fill the form below and we'll try to get back to you as soon as possible.
                </div>
                <form className='brandConnectContactDetails' onSubmit={submitClickHandler}>
                    <input className='brandConnectContactName' type='text' placeholder='Name' required onChange={handleChange} name="username" value={formValues.username} style={{ border: `1px solid ${nameBorder}` }}  />
                    <div className='brandConnectEmailandPhone'>
                        <input type='email' placeholder='Email' required onChange={handleChange} name="email" value={formValues.email} style={{ border: `1px solid ${emailBorder}` }} />
                        <input type='tel' placeholder='Phone Number' required onChange={handleChange} name='phoneNumber' value={formValues.phoneNumber} style={{ border: `1px solid ${phoneNumberBorder}` }}  />
                    </div>
                    <input className='brandConnectCompanyName' type='text' placeholder='Company Name' required onChange={handleChange} name='companyName' value={formValues.companyName} style={{ border: `1px solid ${companyNameBorder}` }}  />
                    <div className='brandConnectSizeandCategory'>
                        <input type='text' placeholder='Size of Company' required onChange={handleChange} name='sizeOfCompany' value={formValues.sizeOfCompany} style={{ border: `1px solid ${sizeOfCompanyBorder}` }} />
                        <input type='text' placeholder='Category' required onChange={handleChange} name='category' value={formValues.category} style={{ border: `1px solid ${categoryBorder}` }} />
                    </div>
                    <textarea className='brandConnectMessageText' type='text' placeholder='Message' required onChange={handleChange} name='message' value={formValues.message} style={{ border: `1px solid ${messageBorder}` }}  />
                    <p>{formErrors.errorMessage}</p>
                    <div className='brandConnectButtonSection'>
                        <button className='brandConnectButton' onClick={submitClickHandler}>
                            SEND
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
