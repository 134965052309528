import React from 'react'
import "./DigitalSampling.css"
import digitalFirstSectionImage from "../Images/digitalSamplingFirstSectionImage.webp"
import fullPercentImage from "../Images/fullPercentImage.webp"
import worksFirstImage from "../Images/digitalSamplingWorksFirstImage.webp"
import worksSecondImage from "../Images/digitalSamplingWorksSecondImage.webp"
import residencyImage from "../Images/digitalSamplingResidency.webp";
import beautyImage from "../Images/beautyandgrooming.webp"
import fragranceImage from "../Images/fragrances.webp"
import makeupImage from "../Images/makeup.webp"
import healthandwealthImage from "../Images/healthandwealth.webp"
import foodImage from "../Images/food.webp"
import momImage from "../Images/momandbaby.webp"
import accessoriesImage from "../Images/accessories.webp"
import homeEssentialsImage from "../Images/homeessentials.webp"
import Footer from '../MainPage/Footer/Footer'
import { useNavigate } from "react-router";

export default function DigitalSampling() {
    const navigate = useNavigate()
    const brandConnectClickHandler = () =>{
        navigate("/pages/brand-connect")
    }
    return (
        <div>
            <div className='digitalSamplingSection'>
                <div className='digitalSamplingFirstSection'>
                <div className='digitalSamplingFirstSectionData'>
                        <div className='digitalSamplingHeading'>
                            DIGITAL SAMPLING AT SMYTTEN
                        </div>
                        <div className='digitalSamplingFirstSectionDescription'>
                            The relevance of digital sampling has not only increased drastically but is also emerging to be one of the most efficient ways for brands to directly reach out to their most relevant consumers leveraging the digital data and insights.
                        </div>
                    </div>
                    <div className='digitalSamplingFirstSectionImage'>
                        <img src={digitalFirstSectionImage} alt="firstImage" />
                    </div>
                </div>
                <div className='digitalSamplingSecondSection'>
                    <div className='digitalSamplingSecondSectionHeading'>
                        Data-driven sampling is quick, safe and effective.
                    </div>
                    <div className='digitalSamplingSecondSectionData'>
                        <div>
                            <div className='digitalSamplingPercentage'>
                                <img src={fullPercentImage} alt="percentImage" />
                            </div>
                            <div className='percentHead'>
                                TARGETED
                            </div>
                        </div>
                        <div className='digitalSamplingPercentage'>
                            <div>
                                <img src={fullPercentImage} alt="percentImage" />
                            </div>
                            <div className='percentHead'>
                                IN-HOME
                            </div>
                        </div>
                        <div className='digitalSamplingPercentage'>
                            <div>
                                <img src={fullPercentImage} alt="percentImage" />
                            </div>
                            <div className='percentHead'>
                                ACCOUNTABLE
                            </div>
                        </div>
                    </div>
                </div>
                <div className='digitalSamplingThirdSection'>
                <div className='digitalSamplinThirdSectionImage'></div>
                    <div className='digitalSamplingThirdSectionData'>By taking a direct-to-home approach, brands are reducing their sampling pilferage and operational challenges to a great extent. Data-driven sampling focuses on delivering lower volumes of more engaging, higher quality experiences – in the comfort and safety of home.
                    </div>
                    <div className='digitalSamplingThirdSectionData1'>
                        The cost per sample is typically higher than the more traditional methods but each sample is hyper-targeted – producing low/no waste, full accountability and a stream of high value, measurable returns. All data-driven sampling activities come with full campaign and product performance reporting – built in, as standard.
                    </div>
                </div>
                <div className='digitalSamplingFourthSection'>
                    <div className='digitalSamplingFourthSectionHeading'>
                        How It Works
                    </div>
                    <div className='digitalSamplingFourthSectionMiddle'>
                        <div className='digitalSamplingFourthSectionMiddleData'>
                            <div className='digitalSamplingFourthSectionMiddleDataHeading'>
                                Target Your Highest-Value Consumers
                            </div>
                            <div className='digitalSamplingFourthSectionMiddleDataDetails'>
                                Leverage our advanced audience targeting to reach your ideal consumer and drive brand awareness.
                            </div>
                            <div className='digitalSamplingFourthSectionMiddleDataDetails'>
                                With a potential reach of 10M and over multiple segments to choose from, you can target and reach consumers by region, demographics, lifestyle, personal interests, dietary preferences and much more.
                            </div>
                        </div>
                        <div className='digitalSamplingFourthSectionMiddleImage'>
                            <img src={worksFirstImage} alt="worksImage" />
                        </div>
                    </div>
                    <div className='digitalSamplingFourthSectionBottom'>
                        <div className='digitalSamplingFourthSectionBottomImage'>
                            <img src={worksSecondImage} alt="secondImage" />
                        </div>
                        <div className='digitalSamplingFourthSectionBottomData'>
                            <div className='digitalSamplingFourthSectionBottomDataHeading'>
                                Deliver Samples To Consumers’ Doorsteps
                            </div>
                            <div className='digitalSamplingFourthSectionBottomDataDetails'>
                                After creating a Smytten account, consumers answer a set of profile questions so we can match them with samples that best fit their lifestyle and interests. Once a consumer selects your sample, we process, package and deliver it directly to their doorstep in a safe and contactless way. They’ll get a chance to experience Smytten trial products they love, including yours, right in the comfort of their own home.
                            </div>
                            <div className='digitalSamplingFourthSectionBottomDataDetails'>
                                Have a product that’s trickier to ship? You can still launch powerful programs by opting for a high value digital offer like a discount coupon instead.
                            </div>
                        </div>
                    </div>

                </div>
                <div className='digitalSamplingFifthSection'>
                    <div className='digitalSamplingFifthSectionHeading'>
                        Reach Over 8 Million Users
                    </div>
                    <div className='digitalSamplingFifthSectionData'>
                        Use hyper targeting on the basis of social profiles, sampling behaviour, purchase pattern and other deep profiling options.
                    </div>
                    <div className='digitalSamplingFifthSectionImage'>
                        <img src={residencyImage} alt="residency" />
                    </div>
                </div>
                <div className='digitalSamplingSixthSection'>
                    <div className='digitalSamplingSixthSectionHeading'>
                        Categories You Can Sample On Smytten
                    </div>
                    <div className='digitalSamplingSixthSectionMiddle'>
                        <div className='wrapSection'>
                            <img src={beautyImage} alt='categories' />
                            <div>
                                Beauty & Grooming
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={fragranceImage} alt='categories' />
                            <div>
                                Fragrances
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={makeupImage} alt='categories' />
                            <div>
                                Makeup
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={healthandwealthImage} alt='categories' />
                            <div>
                                Health & Wellness
                            </div>
                        </div>

                    </div>
                    <div className='digitalSamplingSixthSectionBottom'>
                        <div className='wrapSection'>
                            <img src={foodImage} alt='categories' />
                            <div>
                                Food & Beverages
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={momImage} alt='categories' />
                            <div>
                                Mom & Baby
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={accessoriesImage} alt='categories' />
                            <div>
                                Accessories
                            </div>
                        </div>
                        <div className='wrapSection'>
                            <img src={homeEssentialsImage} alt='categories' />
                            <div>
                                Home Essentials
                            </div>
                        </div>
                    </div>
                </div>
                <div className='digitalSamplingSeventhSection'>
                <div className='digitalSamplingSeventhSectionImage'></div>
                    <div className='digitalSamplingSeventhSectionData'>
                        Smytten aims to build relationships between brands and customers via data-driven sampling and curating new trial products and experiences. Over 600 brands have been able to target the right set of users for sampling, assess product feedback and also execute innovative campaigns for re-engagement post-trial.
                    </div>
                </div>
                <div className='mainPageEighthSection'>
                    <div className='interestedHeading'>
                        Interested in learning more?
                    </div>
                    <div className="interestedData">
                        Get in touch to know how Smytten can help your brand.
                    </div>
                    <button onClick={brandConnectClickHandler}>
                        CONNECT WITH US
                    </button>
                </div>
                <Footer/>
            </div>
        </div>
    )
}
