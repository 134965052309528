import './App.css';
import MainPageHeader from './components/MainPage/MainPageHeader/MainPageHeader';
import { BrowserRouter } from "react-router-dom"
function App() {




  return (
    <div className="App">
      <BrowserRouter>
        <MainPageHeader />
      </BrowserRouter>
    </div>
  );
}

export default App;
