import React from 'react'
import "./MainPageSection.css"
import SampledImage from "../../Images/digitalSampled.webp"
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel'
import slideBrands1 from "../../Images/Brands1.webp"
import slideBrands2 from "../../Images/brands2.webp"
import exposureImage from "../../Images/exposure.webp"
import researchImage from "../../Images/researchImage.webp"
import successImage from "../../Images/successStories.webp"
import PlumBrandsTalkSlide from "../../Images/plumBrandsTalk.webp"
import MamaEarthBrandsTalkSlide from "../../Images/mamaearthBrandsTalk.webp"
import BodyWiseBrandsTalkSlide from "../../Images/bodyWiseBrandsTalk.webp"
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router';
import sliderImage from "../../Images/homepage-banner-new.jpg"

export default function MainPageSection() {
    const navigate = useNavigate()
    const digitalClickHandler = () => {
        navigate("/pages/digital-sampling")
    }
    const advertiseClickHandler = () => {
        navigate("/pages/advertise")
    }
    const researchClickHandler = () => {
        navigate("/pages/research")
    }
    const successStoriesClickHandler = () => {
        navigate("/pages/success-stories")
    }
    const brandConnectClickHandler = () => {
        navigate("/pages/brand-connect")
    }
    const d2cClickHandler = () => {
        navigate("/pages/d2c-awards")
        // setNavBarState(true)
    }
    return (
        <div><div className='forbesSectionSlider mobileHide'>
        <Carousel variant="dark">
            <Carousel.Item className="slideCarousel">
                <img
                    onClick={d2cClickHandler}
                    className="d-block w-100"
                    src={sliderImage}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item className="slideCarousel">
                {/* <img
                className="d-block w-100"
                src={sliderImage1}
                alt="Second slide"
            /> */}
                <div className='mainPageFirstSection mobileHide'>
                    <div className='trySomeThingNewSectionImage'>

                    </div>
                    <div className='trySomeThingNewSection'>
                        <div className='trySomeThingNew'>
                            #TrySomethingNew
                        </div>
                        <div className='trySomeThingNewDescription'>
                            As a brand you can reach millions of relevant consumers & engage with them meaningfully through direct product sampling, targeted advertising & consumer research on Smytten.
                        </div>
                        <button onClick={brandConnectClickHandler}>
                            GET IN TOUCH
                        </button>
                    </div>
                </div>
            </Carousel.Item>

        </Carousel>
    </div>
            <div className='mainPageSection'>
                <div className='mobileMainImage mobileMain'>
                    <img
                        onClick={d2cClickHandler}
                        // className=""
                        src={sliderImage}
                        alt="First slide"
                    />
                </div>
                <div className='mainPageFirstSection mobileMain'>
                    <div className='trySomeThingNewSectionImage'>

                    </div>
                    <div className='trySomeThingNewSection'>
                        <div className='trySomeThingNew'>
                            #TrySomethingNew
                        </div>
                        <div className='trySomeThingNewDescription'>
                            As a brand you can reach millions of relevant consumers & engage with them meaningfully through direct product sampling, targeted advertising & consumer research on Smytten.
                        </div>
                        <button onClick={brandConnectClickHandler}>
                            GET IN TOUCH
                        </button>
                    </div>
                </div>
                <div className='mainPageSecondSection'>
                    <div className='digitalSamplingSection'>
                        <div className='digitalProductSampledHeader'>
                            Digital Product Sampling!
                        </div>
                        <div className='digitalProductSampledCenter'>
                            <div className='digitalSampleddData'>
                                <div >
                                    Smytten provides complete data driven solutions to brands in terms of their targeting, feedback & engagement with their potential consumers in the digital sampling space. Create awareness about your brand and give consumers a chance to experience, taste, smell or feel the product.
                                </div>
                                <button onClick={digitalClickHandler}>
                                    SAMPLE WITH US
                                </button>
                            </div>
                            <div className='digitalSampledImageSection'>
                                <img src={SampledImage} alt='Digital ProductSampled' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mainPageThirdSection'>
                    <div className='companyBrands'>
                        You're in great company!
                    </div>
                    <div className='aboutCompany'>
                        Smytten is India’s largest sampling platform trusted by global FMCG giants as well as smaller emerging brands.
                    </div>

                    <div className='sliderSection'>
                        <Carousel variant="dark">
                            <Carousel.Item className="slideCarousel">
                                <img
                                    className="d-block w-100"
                                    src={slideBrands1}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item className="slideCarousel">
                                <img
                                    className="d-block w-100"
                                    src={slideBrands2}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className='mainPageFourthSection'>
                    <div className='exposureHeading'>
                        Increase your brand exposure!
                    </div>
                    <div className='exposureMiddleSection'>
                        <div className='exposureImage'>
                            <img src={exposureImage} alt="exposureImage" />
                        </div>
                        <div className='exposureData'>
                            <div>
                                To help brands successfully reach & engage with our unique audience, Smytten offers a wide range of advertising solutions. From sponsored content (native advertising), to targeted banner ads, influencer marketing and much more.
                            </div>
                            <button onClick={advertiseClickHandler}>
                                ADVERTISE WITH US
                            </button>
                        </div>
                    </div>

                </div>
                <div className='mainPageFifthSection'>
                    <div className='researchHeading'>
                        Consumer research in 72 hours!
                    </div>
                    <div className='researchMiddleSection'>
                        <div className='researchData'>
                            <div>
                                Smytten has pioneered the concept of ‘72 Hours Research’ where brands can do product testing, concept testing, usage studies in just 72 hours on the platform engaging with more than 10,000 users.
                            </div>
                            <button onClick={researchClickHandler}>
                                STUDY WITH US
                            </button>
                        </div>
                        <div className='researchImage'>
                            <img src={researchImage} alt='mainResearchImage' />
                        </div>
                    </div>
                </div>
                <div className='mainPageSixthSection'>
                    <div className='successHeading'>
                        Success Stories
                    </div>
                    <div className='successStoriesMiddleSection'>
                        <div className='successImage'>
                            <img src={successImage} alt="successStories" />
                        </div>
                        <div className='successStoriesData'>
                            <div>
                                Learn how 800+ brands are using Smytten to build winning sampling programs by reaching & engaging with the right set of consumers.
                            </div>
                            <button onClick={successStoriesClickHandler}>
                                READ MORE
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mainPageSeventhSection'>
                    <div className='brandsTalkHeading'>
                        What Brands Have To Say
                    </div>
                    <div className='sliderSectionBrandsTalk'>
                        <Carousel>
                            <Carousel.Item className="slideCarousel">
                                <img
                                    className="d-block w-100"
                                    src={PlumBrandsTalkSlide}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item className="slideCarousel">
                                <img
                                    className="d-block w-100"
                                    src={BodyWiseBrandsTalkSlide}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item className="slideCarousel">
                                <img
                                    className="d-block w-100"
                                    src={MamaEarthBrandsTalkSlide}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className='mainPageEighthSection'>
                    <div className='interestedHeading'>
                        Interested in learning more?
                    </div>
                    <div className="interestedData">
                        Get in touch to know how Smytten can help your brand.
                    </div>
                    <button onClick={brandConnectClickHandler}>
                        CONNECT WITH US
                    </button>
                </div>
                <Footer />
            </div>
        </div>
    )
}
