import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import SmyttenLogo from "../../Images/logo.png";
import "./MainPageHeader.css"
import MainPageSection from '../MainPageSection/MainPageSection';
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import DigitalSampling from '../../DigitalSampling/DigitalSampling';
import Advertise from '../../Advertise/Advertise';
import Research from '../../Research/Research';
import SuccessStories from '../../SuccessStories/SuccessStories';
import BrandConnect from '../../BrandConnect/BrandConnect';
import AboutUs from '../../AboutUs/AboutUs';
import ContactUs from '../../ContactUs/ContactUs';
import PrivacyPolicy from '../../PrivacyPolicy/PrivacyPolicy';
import NominationPage from '../../D2C/D2CNominationPage/NominationPage';
import Awards from '../../D2C/Awards/Awards';
import 'animate.css';
import NotFound from '../../NotFound/NotFound';
import useDocumentTitle from '../../../useDocumentTitle'
import NominationClosed from '../../D2C/D2CNominationPage/NominationClosed';

export default function MainPageHeader() {
    const { pathname } = useLocation();
    const [title, setTitle] = useState('Smytten | Discover')
    useDocumentTitle(title);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const [navBarState, setNavBarState] = useState(true)
    const navigate = useNavigate()
    const navBarClickHandler = () => {
        setNavBarState(!navBarState)
    }
    const facebookClickHandler = () => {
        window.open("https://www.facebook.com/GetSmytten/", "_blank")
    }
    const twitterClickHandler = () => {
        window.open("https://twitter.com/getsmytten", "_blank")
    }
    const instagramClickHandler = () => {
        window.open("https://www.instagram.com/getsmytten/", "_blank")
    }
    const digitalClickHandler = () => {

        navigate("/pages/digital-sampling")
        setNavBarState(true)
    }
    const advertiseClickHandler = () => {

        navigate("/pages/advertise")
        setNavBarState(true)
    }
    const researchClickHandler = () => {

        navigate("/pages/research")
        setNavBarState(true)
    }
    const successStoriesClickHandler = () => {
        navigate("/pages/success-stories")
        setNavBarState(true)
    }
    const brandConnectClickHandler = () => {
        navigate("/pages/brand-connect")
        setNavBarState(true)
    }
    const privacyPolicyClickHandler = () => {
        navigate("/pages/smytten-privacy-policy")
        setNavBarState(true)
    }
    const mainPageLogoClickHandler = () => {
        navigate("/")
        setNavBarState(true)
    }
    const d2cClickHandler = () => {

        navigate("/pages/d2c-awards")

        setNavBarState(true)
    }

    useEffect(() => {
        let a = window.location.pathname.split('/')
        if (a.length > 2) {
            setTitle(a[a.length - 1])
        } else {
            setTitle('Smytten | Discover')
        }
    }, [window.location.pathname])


    return (
        <div>
            <div className='headerSection'>
                <div className='header'>
                    <FontAwesomeIcon className='navBar' onClick={navBarClickHandler} icon={faBars} />
                    <img src={SmyttenLogo} onClick={mainPageLogoClickHandler} alt="Smytten Logo" />
                </div>
                <div>
                    {
                        navBarState ?
                            <>
                                <div className='homeMenu'>
                                    <div onClick={d2cClickHandler}>
                                        D2C Disruptors
                                    </div>
                                    <div onClick={digitalClickHandler}>
                                        Digital Sampling
                                    </div>
                                    <div onClick={advertiseClickHandler}>
                                        Advertise
                                    </div>
                                    <div onClick={researchClickHandler}>
                                        Research
                                    </div>
                                    <div onClick={successStoriesClickHandler}>
                                        Success Stories
                                    </div>
                                </div>

                                <Routes>
                                    <Route path='/' element={<MainPageSection />} />
                                    <Route path="/pages/d2c-nomination" element={<NominationClosed />} />
                                    <Route path='/pages/d2c-awards' element={<Awards />} />
                                    <Route path="/pages/digital-sampling" element={<DigitalSampling />} />
                                    <Route path="/pages/advertise" element={<Advertise />} />
                                    <Route path="/pages/research" element={<Research />} />
                                    <Route path="/pages/success-stories" element={<SuccessStories />} />
                                    <Route path="/pages/brand-connect" element={<BrandConnect />} />
                                    <Route path='/pages/about-us' element={<AboutUs />} />
                                    <Route path='/pages/contact-us' element={<ContactUs />} />
                                    <Route path="/pages/smytten-privacy-policy" element={<PrivacyPolicy />} />
                                    <Route path='/*' element={<NotFound />} />
                                </Routes>

                            </>
                            : <>
                                <div className='navBarHomeMenu '>
                                    <div onClick={d2cClickHandler} className="animate__animated animate__slideInDown">
                                        D2C Disruptors
                                    </div>
                                    <div onClick={digitalClickHandler} className="animate__animated animate__slideInDown">
                                        Digital Sampling
                                    </div>
                                    <div onClick={advertiseClickHandler} className="animate__animated animate__slideInDown">
                                        Advertise
                                    </div>
                                    <div onClick={researchClickHandler} className="animate__animated animate__slideInDown">
                                        Research
                                    </div>
                                    <div onClick={successStoriesClickHandler} className="animate__animated animate__slideInDown">
                                        Success Stories
                                    </div>
                                    <div onClick={brandConnectClickHandler} className="animate__animated animate__slideInDown">
                                        Brand Connect
                                    </div>
                                    <div onClick={privacyPolicyClickHandler} className="animate__animated animate__slideInDown">
                                        Privacy Policy
                                    </div>
                                </div>
                                <div className='socialAccounts animate__animated animate__slideInDown'>
                                    <FontAwesomeIcon onClick={facebookClickHandler} icon={faFacebookF} />
                                    <FontAwesomeIcon onClick={twitterClickHandler} icon={faTwitter} />
                                    <FontAwesomeIcon onClick={instagramClickHandler} icon={faInstagram} />
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
