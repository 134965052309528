import React, { useState } from 'react'
import "./Footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router';
import cancelImage from "../../Images/cancel.svg"

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import MyVerticallyCenteredModal from './Modal';

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);

    const facebookClickHandler = () => {
        window.open("https://www.facebook.com/GetSmytten/", "_blank")
    }
    const twitterClickHandler = () => {
        window.open("https://twitter.com/getsmytten", "_blank")
    }
    const instagramClickHandler = () => {
        window.open("https://www.instagram.com/getsmytten/", "_blank")
    }
    const navigate = useNavigate()
    const digitalClickHandler = () => {
        navigate("/pages/digital-sampling")
    }
    const advertiseClickHandler = () => {
        navigate("/pages/advertise")
    }
    const researchClickHandler = () => {
        navigate("/pages/research")
    }
    const successStoriesClickHandler = () => {
        navigate("/pages/success-stories")
    }
    const homeClickHandler = () => {
        navigate("/")
    }
    const aboutUsClickHandler = () => {
        navigate("/pages/about-us")
    }
    const contactUsClickHandler = () => {
        navigate("/pages/contact-us")
    }
    const brandConnectClickHandler = () => {
        navigate("/pages/brand-connect")

    }
    const privacyPolicyClickHandler = () => {
        navigate("/pages/smytten-privacy-policy")
    }

    const downloadClickHandler = () => {
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.app.smytten';
        }
        else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
            window.location.href = 'https://apps.apple.com/in/app/smytten-product-trials-app/id1100171914';
        } else {
            window.location.href = 'https://smytten.com/';
        }
    }
  

    return (
        <div className='footer'>
            <div className='footerFirstSection'>
                <div className='footerLeftSection'>
                    <ul>
                        <li onClick={homeClickHandler}>
                            HOME
                        </li>
                        <li onClick={downloadClickHandler}>
                            DOWNLOAD THE APP
                        </li>
                        <li onClick={aboutUsClickHandler}>
                            ABOUT US
                        </li>
                        <li onClick={contactUsClickHandler}>
                            CONTACT US
                        </li>
                        {/* <li>
                        VIEW CART
                    </li> */}
                        <li>
                            TERMS & CONDITIONS
                        </li>
                    </ul>
                    <ul>
                        <li onClick={digitalClickHandler}>
                            Digital Sampling
                        </li>
                        <li onClick={advertiseClickHandler}>
                            Advertise
                        </li>
                        <li onClick={researchClickHandler}>
                            Research
                        </li>
                        <li onClick={successStoriesClickHandler}>
                            Success Stories
                        </li>
                        <li onClick={brandConnectClickHandler}>
                            Brand Connect
                        </li>
                        <li onClick={privacyPolicyClickHandler}>
                            Privacy Policy
                        </li>
                    </ul>
                </div>
                {/* <div className='footerRightSection'>
                    <div>
                        SIGN IN FOR UPDATES
                    </div>
                    <form type='submit'>
                        <input type='email' placeholder="My email address" required></input>
                        <button type='submit'>OK</button>
                    </form>
                </div> */}
            </div>
            <div className='footerSecondSection'>
                <div className='footerSocialAccounts'>
                    <FontAwesomeIcon onClick={facebookClickHandler} icon={faFacebookF} />
                    <FontAwesomeIcon onClick={twitterClickHandler} icon={faTwitter} />
                    <FontAwesomeIcon onClick={instagramClickHandler} icon={faInstagram} />
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div className='copyRight'>
                    Copyright © 2022, <Link className='homeSmytten' to="/">Smytten.</Link>
                </div>
            </div>
            {/* <Button className='contactButton' variant="primary" onClick={() => setModalShow(true)}>
                Contact Us
            </Button> */}
            <img className='contactEnvelope' variant="primary" onClick={() => setModalShow(true)} src={cancelImage} alt="envelope" />
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow = {setModalShow}
            />
        </div>
    )
}
