import React from 'react'
import "./AboutUs.css"
import aboutUsFirstImage from "../Images/aboutUsFirst.webp"
import aboutUsSecondImage from "../Images/aboutUsSecond.webp"
import aboutUsThirdImage from "../Images/aboutUsThird.webp"
import aboutUsSixthImage from "../Images/aboutUsSixth.webp"
import aboutUsSeventhImage from "../Images/aboutUsSeventh.webp"
import Footer from "../MainPage/Footer/Footer"

export default function AboutUs() {
  return (
    <div>
      <div className='aboutUsSection'>
        <div className='aboutUsSectionHeader'>
          Who are we?
        </div>
        <div className='aboutUsSectionData'>
          We are India's largest premium discovery and trial platform where you can enjoy a specially curated online shopping experience hassle-free.
        </div>
        <div className='aboutUsSectionFirstImage'>
          <img src={aboutUsFirstImage} alt='firstImage' />
        </div>
        <div className='aboutUsSecondSection'>
          <div className='aboutUsSecondSectionImage'>
            <img src={aboutUsSecondImage} alt='SecondImage' />
          </div>
          <div className='aboutUsSecondSectionData'>
            <div className='aboutUsSecondSectionDataHeader'>
              #TrySomethingNew
            </div>
            <div className='aboutUsSecondSectionDataDescription'>
              The journey of discovery is exhilarating. And the unforgettable feeling of trying something new never gets old.
              With every second you spend with us, we strive to blend that beautiful feeling into your online shopping experience. Our exciting and truly delightful curations are tailored to meet your needs.
            </div>
          </div>
        </div>
        <div className='aboutUsThirdSection'>
          <div className='aboutUsThirdSectionData'>
            <div className='aboutUsThirdSectionDataHeader'>
              Our offerings
            </div>
            <div className='aboutUsThirdSectionDataDescription'>
              Finding a product that you can fall in love with or simply choosing the perfect gift just got easier!
              <br></br>
              <ul>
                <li>
                  <b>Free Trials</b> from 500+ Premium Indian & International brands. Try up to 6 products, only pay for servicing & shipping, and get it as 100% cashback to buy what you love!
                </li>
                <li>
                  <b>Shop from 700+ Premium Brands</b> across categories like Makeup, Skincare, Fragrances, Grooming, Bath & Body, Baby Care, Food & Beverages, Smart Technology, and much more!
                </li>
                <li>
                  <b>100% Verified Authentic</b> products sourced straight from brands, with exclusive offers.
                </li>
              </ul>
            </div>
          </div>
          <div className='aboutUsThirdSectionImage'>
            <img src={aboutUsThirdImage} alt='ThirdImage' />
          </div>
        </div>
        <div className='aboutUsFourthSection'>
          <iframe width="900" height="500" src="https://www.youtube.com/embed/XQ-typkCXAI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className='aboutUsFifthSection'>
          <div className='aboutUsFifthSectionHeader'>
            How do Free Trials work?
          </div>
          <ul>
            <li>
              Step 1 - Open the <b>
                Smytten App
              </b> & Register to become a Member.
            </li>
            <div className='downloadHere'>(Download Here)</div>
            <li>
              Step 2 - Once you are a member, you automatically unlock 6 Trial Points.
            </li>
            <li>
              Step 3 - Use these trial points at checkout to order upto 6 Free Trials.
            </li>
            <li>
              Step 4 - Make a purchase with your 100% cashback and get more Trial Points.
            </li>
            <li>
              Step 5 - Repeat & don’t forget to Try Something New every day!
            </li>
          </ul>
        </div>
        <div className='aboutUsSixthSection'>
          <div className='aboutUsSixthSectionData'>
            <div className='aboutUsSixthSectionDataHeader'>
              Discover your favourite brands
            </div>
            <div className='aboutUsSixthSectionDataDescription'>
              Choose from thousands of products curated especially for your lifestyle needs.
              Enjoy amazing deals & offers on the best of brands like Mama Earth, Plum, WOW Skin, Faces Canada, The Man Company, Sugar, Lakme, mCaffeine & many more!
            </div>
          </div>
          <div className='aboutUsSixthSectionImage'>
            <img src={aboutUsSixthImage} alt='SixthImage' />
          </div>
        </div>
        <div className='aboutUsSeventhSection'>
          <div className='aboutUsSeventhSectionImage'>
            <img src={aboutUsSeventhImage} alt='SeventhImage' />
          </div>
          <div className='aboutUsSeventhSectionData'>
            <div className='aboutUsSeventhSectionDataHeader'>
              The Founders
            </div>
            <div className='aboutUsSeventhSectionDataDescription'>
              Swagata Sarangi and Siddhartha Nangia, two ISB alumni with over 25 years of collective experience working for companies such as Google, McKinsey, and Unilever, founded Smytten in November 2015.
            </div>
          </div>
        </div>
        <div className='aboutUsEightSection'>
          <div className='aboutUsEightSectionHeader'>
            We'd love to hear from you!
          </div>
          <div className='aboutUsEightSectionData'>
            Drop us an email at care@smytten.com
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  )
}
