import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import contactUsImage from "../../Images/contactUsPopUp.jpeg"
import axios from "axios"
let emailBorder
let nameBorder
let phoneNumberBorder
let companyNameBorder
let sizeOfCompanyBorder
let categoryBorder
let messageBorder
export default function MyVerticallyCenteredModal(props) {
    const initialValues = { username: "", email: "", phoneNumber: "", companyName: "", sizeOfCompany: "", category: "", message: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const submitClickHandler = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        if (Object.keys(validate(formValues)).length === 0) {
            axios.post(`https://ancillary-prod.smytten.com/ancillary_backend/v1/forbes/contactus/`,
                formValues
            )
                .then(res => {
                    props.setModalShow(false)
                    setFormValues({ username: "", email: "", phoneNumber: "", companyName: "", sizeOfCompany: "", category: "", message: "" })
                })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = (values) => {
        const errors = {};
        let color = '#EE504F'
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.username) {
            errors.username = "Username is required!";
            nameBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.email) {
            errors.email = "Email is required!";
            emailBorder = color
            errors.errorMessage = "Please fill required fields"
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            emailBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Phone number not entered'
            phoneNumberBorder = color
            errors.errorMessage = "Please fill required fields"
        } else if (!/^\d+$/.test(values.phoneNumber) || values.phoneNumber.length !== 10) {
            errors.phoneNumber = 'Phone number not entered'
            phoneNumberBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.companyName) {
            errors.companyName = 'company name not entered'
            companyNameBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.sizeOfCompany) {
            errors.sizeOfCompany = 'please enter the company size'
            sizeOfCompanyBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.category) {
            errors.category = 'please enter the category'
            categoryBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        if (!values.message) {
            errors.message = 'please enter the message'
            messageBorder = color
            errors.errorMessage = "Please fill required fields"
        }
        return errors;
    };

    useEffect(() => {
        if (props.show === false) {
            emailBorder = ''
            nameBorder = ''
            phoneNumberBorder = ''
            companyNameBorder = ''
            sizeOfCompanyBorder = ''
            categoryBorder = ''
            messageBorder = ''
            setFormErrors({ errorMessage: "" })
        }
    }, [props.show])

  

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modalHeader'>
            </Modal.Header>
            <Modal.Body className='modalBody'>
                <img src={contactUsImage} alt='contactUs' />
                <div className='popUpDetails'>
                    <h3>Contact Us</h3>
                    <p>Leave your message here and we will reply to you shortly.</p>
                    <form type="submit" onSubmit={submitClickHandler}>
                        <input className='contactName' type='text' placeholder='Name' name="username" value={formValues.username}
                            onChange={handleChange} style={{ border: `1px solid ${nameBorder}` }} />
                        <div className='emailandPhone'>
                            <input type='email' placeholder='Email' name="email" value={formValues.email}
                                onChange={handleChange} style={{ border: `1px solid ${emailBorder}` }} />
                            <input type='tel' placeholder='Phone Number' name='phoneNumber' value={formValues.phoneNumber} onChange={handleChange} style={{ border: `1px solid ${phoneNumberBorder}` }} />
                        </div>
                        <input className='companyName' type='text' placeholder='Company Name' name='companyName' value={formValues.companyName} onChange={handleChange} style={{ border: `1px solid ${companyNameBorder}` }} />
                        <div className='sizeandCategory'>
                            <input type='text' placeholder='Size of Company' name='sizeOfCompany' value={formValues.sizeOfCompany} onChange={handleChange} style={{ border: `1px solid ${sizeOfCompanyBorder}` }} />
                            <input type='text' placeholder='Category' name='category' value={formValues.category} onChange={handleChange} style={{ border: `1px solid ${categoryBorder}` }} />
                        </div>
                        <textarea className='messageText' type='text' placeholder='Message' name='message' value={formValues.message} onChange={handleChange} style={{ border: `1px solid ${messageBorder}` }} />
                        <p>{formErrors.errorMessage}</p>
                        <div className='buttonDiv'>
                            <button onClick={submitClickHandler}>Submit</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}
