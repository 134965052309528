import React from 'react'
import "./Advertise.css"
import advertiseFirstImage from "../Images/advertiseFIrstSection.webp"
import advertiseSecondImage from "../Images/advertiseSecondSectionImage.webp"
import advertiseThirdImage from "../Images/advertiseThirdSection.webp"
import advertiseFourthImage from "../Images/advertiseFourthSection.webp"
import advertiseFifthImage from "../Images/advertiseFifthSection.webp"
import Footer from '../MainPage/Footer/Footer'
import { useNavigate } from "react-router";

export default function Advertise() {
  const navigate  = useNavigate()
  const brandConnectClickHandler = () =>{
    navigate("/pages/brand-connect")
}
  return (
    <div>
      <div className='advertiseSection'>
      <div className='advertiseFirstSection'>
          <div className='advertiseFirstSectionData'>
            <div className='advertiseFirstSectionHeading'>
              ADVERTISE WITH SMYTTEN
            </div>
            <div className='advertiseFirstSectionDescription'>
              Boost your exposure and reach by advertising on Smytten and engage meaningfully with relevant target audience.
            </div>
          </div>
          <div className='advertiseFirstSectionImage'>
            <img src={advertiseFirstImage} alt="firstImage" />
          </div>
        </div>
        <div className='advertiseSecondSection'>
          <div className='advertiseSecondSectionImage'>
            <img src={advertiseSecondImage} alt="SecondImage" />
          </div>
          <div className='advertiseSecondSectionData'>
            <div className='advertiseSecondSectionHeading'>
              Intent Based Targeting
            </div>
            <div className='advertiseSecondSectionDescription'>
              Reach the audience that matters most to you. Target and connect with the users on Smytten based on their interests, usage behaviors, location, intent, feedback and more.
            </div>
          </div>
        </div>
        <div className='advertiseThirdSection'>
          <div className='advertiseThirdSectionData'>
            <div className='advertiseThirdSectionHeading'>
              Innovative Formats
            </div>
            <div className='advertiseThirdSectionDescription'>
              Keep up with the new-age marketing trends with rich media engagement. Smytten’s innovative ad formats show off your products through immersive, full-screen mobile ads that inspire action.
            </div>
          </div>
          <div className='advertiseThirdSectionImage'>
            <img src={advertiseThirdImage} alt="ThirdImage" />
          </div>
        </div>
        <div className='advertiseFourthSection'>
          <div className='advertiseFourthSectionImage'>
            <img src={advertiseFourthImage} alt="FourthImage" />
          </div>
          <div className='advertiseFourthSectionData'>
            <div className='advertiseFourthSectionHeading'>
              Power of Influencers
            </div>
            <div className='advertiseFourthSectionDescription'>
              Engaging with your audience via this earned media acts as a trusted word-of-mouth, quickly growing brand awareness, whilst boosting your online engagement and influencing purchase decisions.
            </div>
          </div>
        </div>
        <div className='advertiseFifthSection'>
          <div className='advertiseFifthSectionData'>
            <div className='advertiseFifthSectionHeading'>
              Editorial Features
            </div>
            <div className='advertiseFifthSectionDescription'>
              Create a voice for your brand that connects with consumers. Our featured editorial content is written from your brand’s unique voice, and it conveys expert information and opinions to create a powerful impact on the readers.
            </div>
          </div>
          <div className='advertiseFifthSectionImage'>
            <img src={advertiseFifthImage} alt="FifthImage" />
          </div>
        </div>
        <div className='mainPageEighthSection'>
          <div className='interestedHeading'>
            Interested in learning more?
          </div>
          <div className="interestedData">
            Get in touch to know how Smytten can help your brand.
          </div>
          <button onClick={brandConnectClickHandler}>
            CONNECT WITH US
          </button>
        </div>
        <Footer />
      </div>
    </div>
  )
}
