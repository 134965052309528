import React from 'react'
import Footer from '../MainPage/Footer/Footer'
import "./ContactUs.css"

export default function ContactUs() {
    return (
        <div>
            <div className='contactUsSection'>
                <div className='contactUsSectionHeader'>
                    Contact Us
                </div>
                <div className='contactUsSectionData'>
                    At Smytten, we’ve always loved hearing from all of you out there!
                    <br></br>
                    <br>
                    </br>
                    Do write to us at <b>care@smytten.com</b> with any of your queries, feedback, or a general hello, and we’ll ensure that we’ll get back to you as soon as possible!
                </div>
<Footer/>
            </div>
        </div>
    )
}
